/*
PERINGATAN DARURAT
URL: ?
AUTHOR: Rakyat Indonesia
EMAIL: peringatanri.00@gmail.com
CREATE DATE: Aug 23, 2024
UPDATE DATE: Aug 23, 2024
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: Peringatan Darurat | Kalau kalian melihat poster berwarna biru dengan tulisan peringatan darurat, ini memang darurat.
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'VCROSDMono';
  src: url('./assets/font/VCROSDMono.woff2') format('woff2'),
       url('./assets/font/VCROSDMono.woff') format('woff'),
       url('./assets/font/VCROSDMono.ttf') format('ttf'),
       url('./assets/font/VCROSDMono.eot') format('eot'),
       url('./assets/font/VCROSDMono.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: rgb(0, 0, 109);
  color: #FFF;
  font-size: 12px;
  line-height: 12px;
  font-family: 'VCROSDMono', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}

a{
  color: #FFF;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'VCROSDMono', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.hero{
  position: relative;
  text-align: center;
  min-height: 90vh;
  overflow: hidden;

  > video{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  > div{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 40px;
    right: 40px;
    z-index: 2;

    &.grad{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00006d+0,00006d+100&0+0,1+100 */
      background: linear-gradient(to bottom,  rgba(0,0,109,0) 0%,rgba(0,0,109,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      left: 0;
      right: 0;
      height: 100px;
      top: auto;
      transform: translate(0, 0);
      bottom: 0;
      z-index: 1;
    }

    > h1,
    > h2{
      font-weight: normal;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, .3);
    }

    > h1{
      font-size: 44px;
      line-height: 44px;
      animation: text 1s infinite ease-in-out;

      &:before,
      &:after{
        content: attr(data-content);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      &:before{
        color: cyan;
        animation: glitch1 2.5s infinite;
      }

      &:after{
        color: magenta;
        animation: glitch2 2.5s infinite;
      }
    }
  
    > h2{
      font-size: 84px;
      line-height: 84px;
      animation: text 1.1s infinite ease-in-out;
      animation-delay: .4s;

      &:before,
      &:after{
        content: attr(data-content);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &:before{
        color: cyan;
        animation: glitch1 2.5s infinite;
      }

      &:after{
        color: magenta;
        animation: glitch2 2.5s infinite;
      }
    }

    .logo{
      max-width: 350px;
      margin: 60px auto 40px auto;

      > div{
        position: relative;
        height: 0;
        padding: 50%;
        
        > svg{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          &:nth-child(1){
            animation: logo 1.2s infinite ease-in-out;
            animation-delay: .2s;
            z-index: 0;
          }

          &:nth-child(2){
            color: cyan;
            animation: glitch1 2.5s infinite;
            z-index: 1;
          }

          &:nth-child(3){
            color: magenta;
            animation: glitch2 2.5s infinite;
            z-index: 1;
          }
        }
      }
    }
  }
}

@keyframes text{
  0% { opacity: 1; filter: blur(.75px); }
  25% { opacity: .5; filter: blur(.75px); }
  26% { opacity: .5; filter: blur(.75px); }
  50% { opacity: 1; filter: blur(.5px); }
  100% { opacity: 1; filter: blur(.75px); }
}

@keyframes logo{
  0% { opacity: 1; filter: blur(.75px) drop-shadow(5px 5px 10px rgba(0, 0, 0, .5)); }
  25% { opacity: .5; filter: blur(.5px) drop-shadow(5px 5px 10px rgba(0, 0, 0, .5)); }
  26% { opacity: .5; filter: blur(.5px) drop-shadow(5px 5px 10px rgba(0, 0, 0, .5)); }
  50% { opacity: 1; filter: blur(.5px) drop-shadow(5px 5px 10px rgba(0, 0, 0, .5)); }
  100% { opacity: 1; filter: blur(.75px) drop-shadow(5px 5px 10px rgba(0, 0, 0, .5)); }
}

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 0.1;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.1;
  }
  27% {
    transform: none;
    opacity: 0.1;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.1;
  }
  52% {
    transform: none;
    opacity: 0.1;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.1;
  }
  72% {
    transform: none;
    opacity: 0.1;
  }
  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.1;
  }
  100% {
    transform: none;
    opacity: 0.1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.1;
  }
  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.1;
  }
  27% {
    transform: none;
    opacity: 0.1;
  }
  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }
  35% {
    transform: none;
    opacity: 0.1;
  }
  52% {
    transform: none;
    opacity: 0.1;
  }
  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }
  50% {
    transform: none;
    opacity: 0.1;
  }
  72% {
    transform: none;
    opacity: 0.1;
  }
  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }
  80% {
    transform: none;
    opacity: 0.1;
  }
  100% {
    transform: none;
    opacity: 0.1;
  }
}

.count{
  position: relative;
  margin: 25px 0 0 0;
  padding: 0 20px 20px 20px;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  z-index: 1;

  > div{
    background: rgba(0, 0, 0, .25);
    max-width: 750px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;

    > div{
      display: inline-block;
      vertical-align: middle;
      background: red;
      padding: 10px;
      margin: -60px 0 20px 0;
      font-size: 18px;
      line-height: 22px;

      > span{
        display: block;
        font-size: 28px;
        line-height: 28px;
      }
    }
  }
}

.content{
  padding: 20px;
  max-width: 750px;
  margin: -15px auto;
  font-size: 18px;
  line-height: 22px;

  > p{
    margin: 15px 0;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, .2);

    > .yellow{
      color: yellow;
    }

    &.cenblink{
      position: relative;
      margin: 25px 0;
      text-align: center;
      font-size: 22px;
      line-height: 26px;
      animation: blinker 1s linear infinite;
      
      &:before,
      &:after{
        content: attr(data-content);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      &:before{
        color: cyan;
        animation: glitch1 2.5s infinite;
      }

      &:after{
        color: magenta;
        animation: glitch2 2.5s infinite;
      }
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.cta{
  padding: 20px;
  max-width: 750px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, .2);

  > button{
    position: relative;
    color: yellow;
    background: red;
    padding: 15px;
    font-size: 22px;
    line-height: 26px;
    margin: 10px auto 0 auto;

    &:before,
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &:before{
      background: cyan;
      animation: glitch1 2.5s infinite;
    }

    &:after{
      background: magenta;
      animation: glitch2 2.5s infinite;
    }
  }
}

.congrats{
  padding: 20px;
  max-width: 750px;
  margin: 0 auto;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, .2);

  > div{
    background: rgba(0, 0, 0, .25);
    padding: 20px 20px 25px 20px;
    box-sizing: border-box;

    > button{
      font-size: 12px;
      line-height: 14px;
      background: transparent;
      color: #FFF;
      text-decoration: underline;
      padding: 0;
      margin: 10px 0 0 0;
    }
  }
}

footer{
  bottom: 0;
  padding: 20px 20px 40px 20px;
  text-align: center;

  a{
    font-weight: bold;
  }

  > .note{
    padding: 0 0 10px 0;
    opacity: .75;
    font-size: 14px;
    line-height: 18px;
  }

  > .list-share{
    padding: 0 10px 10px 10px;

    > li{
      display: inline-block;
      padding: 0 10px 10px 10px;

      > button{
        font-size: 20px !important;
        line-height: 20px !important;
      }
    }
  }

  > .list-foot{
    padding: 0 10px;

    > li{
      display: inline-block;
      vertical-align: top;
      padding: 10px;
      font-size: 14px;
      line-height: 18px;
      box-sizing: border-box;
      
      > span{
        opacity: .75;
      }

      > ul{
        margin: -2.5px;

        > li{
          padding: 2.5px;
          font-size: 12px;
          line-height: 16px;

          > span{
            display: block;
            font-size: 11px;
            line-height: 15px;
          }

          // > a{
          //   font-size: 12px;
          //   line-height: 16px;
          // }
        }
      }
    }
  }

  > .copy{
    padding: 20px 0 0 0;
    opacity: .5;
    font-size: 11px;
    line-height: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){
  .hero{
    > div{
      > h1{
        margin: 0 -20px;
        font-size: 32px;
        line-height: 32px;

        &:before,
        &:after{
          left: -20px;
          right: -20px;
        }
      }
    }
  }

  footer{
    > .list-share{
      padding: 0 0 15px 0;
      
      > li{
        padding: 0 5px 5px 5px;
      }
    }
    
    > .list-foot{
      > li{
        width: 50%;
      }
    }
  }
}

// @media screen and (min-width: 1025px) and (max-width: 1600px){}

// @media screen and (min-width: 768px) and (max-width: 1024px){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

// @media screen and (max-width: 767px){}

// @media screen and (max-width: 767px) and (orientation: landscape){}

// @media screen and (max-width: 767px) and (orientation: portrait){}